







import React, { useState } from 'react';
import logo_sidebar from '../../../assets/images/logo-inner.svg'
import Manage_Users from '../../../assets/images/manage-user.svg'
import Manage_Posts from '../../../assets/images/manage-post.svg'
import Manage_Properties from '../../../assets/images/Manage-properties.svg'
import Support_Requests from '../../../assets/images/support-requests.svg'
import Report_Request from '../../../assets/images/report-request.svg'
import { NavLink, Link } from 'react-router-dom';
import dashboard from '../../../assets/images/dashboard.svg'
import burgermenu from '../../../assets/images/burger-menu.svg'
import Modal from '../../common/modal/modal';
import logout from '../../../assets/images/logout.svg'



const Sidebar = ({ isSidebarOpen }) => {
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);

    const openLogoutModal = () => {
        setLogoutModalOpen(true);
    };

    const closeLogoutModal = () => {
        setLogoutModalOpen(false);
    };

    const handleLogout = () => {
      
        localStorage.clear();
        closeLogoutModal();
        window.location.href = '/'; // Navigate to '/' after logout

    };
    


    
    return (
        <>
           

            <div

                className={`LeftSideDiv bg-white w-300 lg:w-auto px-6 pt-4 fixed h-screen z-20 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:translate-x-0 transition-transform duration-300 ease-in-out`}
            >



                <div className="WebMenuBar flex flex-col items-center">
                    <nav className="sidebar-nav">
                       
                        <ul className="menulist">
                            <li className="font-poppins font-normal  text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/dashboard" className=" flex items-center p-3 w-full   " >
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={dashboard} alt="" className="w-full h-full" />
                                    </span>
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="font-poppins font-normal  text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/manage-users" className=" flex items-center p-3 w-full   " >
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={Manage_Users} alt="" className="w-full h-full" />
                                    </span>
                                    Manage Users
                                </NavLink>
                            </li>
                            <li className="font-poppins font-normal text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/manage-posts" className="flex items-center p-3 w-full ">
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={Manage_Posts} alt="" className="w-full h-full" />
                                    </span>
                                    Manage Posts
                                </NavLink>
                            </li>
                            <li className="font-poppins font-normal text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/manage-properties" className="flex items-center p-3 w-full ">
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={Manage_Properties} alt="" className="w-full h-full" />
                                    </span>
                                    Manage Properties
                                </NavLink>
                            </li>
                            <li className="font-poppins font-normal text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/support-requests" className="flex items-center p-3 w-full ">
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={Support_Requests} alt="" className="w-full h-full" />
                                    </span>
                                    Support Requests
                                </NavLink>
                            </li>
                            <li className="font-poppins font-normal text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/report-request" className="flex items-center p-3 w-full ">
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={Report_Request} alt="" className="w-full h-full" />
                                    </span>
                                    Report Requests
                                </NavLink>
                            </li>
                           

                            <li className="font-poppins font-normal text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start   mb-2 rounded-lg">
                                <NavLink to="/support-requests" className="flex items-center p-3 w-full ">
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={Support_Requests} alt="" className="w-full h-full" />
                                    </span>
                                    NFT Owners
                                </NavLink>
                            </li>

                            <li className="font-poppins font-normal text-gray-600 text-base leading-6 hover:bg-black hover:text-white flex items-center justify-start mb-2 rounded-lg">
                                <button className="flex items-center p-3 w-full hover:bg-black " onClick={openLogoutModal}>
                                    <span className="w-8 h-8 inline-block mr-3">
                                        <img src={logout} alt="" className="w-full h-full" />
                                    </span>
                                    Logout
                                </button>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
            {/* Logout Confirmation Modal */}
            <Modal
                isOpen={logoutModalOpen}
                onClose={closeLogoutModal}
                title="Logout Confirmation"
                content={
                    <div className="text-gray-700 flex flex-col items-center">
                        <p className="mb-8 text-2xl font-bold">
                            Are you sure you want to logout?
                        </p>
                        <div className="flex flex-col sm:flex-row sm:space-x-6">
                            <button
                                className="bg-red-500 text-white w-full sm:w-auto px-16 py-4 rounded-md mb-4 sm:mb-0 hover:bg-red-600 focus:outline-none focus:ring focus:border-blue-300"
                                onClick={handleLogout}
                            >
                                Yes
                            </button>
                            <button
                                className="bg-blue-500 text-white w-full sm:w-auto px-16 py-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                                onClick={closeLogoutModal}
                            >
                                No
                            </button>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default Sidebar;
