// src/config/index.js
const config = {
    // baseURL: 'https://api.obirealestate.com',
    // baseURL: 'http://54.241.214.246:3000',
    baseURL:'https://apidev.obirealestate.com',
    imagebaseUrl:"https://obi-stage-bucket.s3.us-west-1.amazonaws.com/obilogo.png"
    
  };
  
  export default config;
  
